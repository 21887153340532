import * as React from 'react';
import axios from 'axios';

import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Stack from 'react-bootstrap/Stack';

import Button from 'react-bootstrap/Button';
import Carousel from 'react-bootstrap/Carousel';
import { SubscriptionCard, dummySubscriptionPlans, dummySubscriptionPlansBlocked } from '../../elements/SubscriptionCard';
// const API_ENDPOINT_DETECT_IMAGE = `${process.env.REACT_APP_API_HOST_IP}/api/v1/detect/image`
import { TopHeader, SectionHeader, ParagraphText, ParagraphTextShort} from '../../elements/Text';

const Subscription = () => {
 
  return (
    <>
    <Stack gap={2} className="col-md-5 mx-auto">
      <center>
        <TopHeader>Оформить подписку</TopHeader>
      </center>
      {dummySubscriptionPlansBlocked.map((subscriptionPlan) => (
        <SubscriptionCard subscriptionPlan={subscriptionPlan}/>
      ))}
    </Stack>
    </>
  );
}

export default Subscription;