import * as React from 'react';
import axios from 'axios';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

const API_ENDPOINT_DOWNLOAD = `${process.env.REACT_APP_API_HOST_IP}/api/v1/download`

const FormDownloadResult = () => {
  const [targetFilename, setTargetFilename] = React.useState('');

  const [selectedFile, setSelectedFile] = React.useState(null);
  const [queryResult, setQueryResult] = React.useState(null);
  const [isRequestInProcess, setIsRequestInProcess] = React.useState(false);

  const handleTargetFilenameChange = (event) => {
    const {
      target: { value },
    } = event;
    setTargetFilename(value)
  };
  
  const handleDownloadAction = () => {
    // setQueryResult(null)
    setIsRequestInProcess(true)

    const formData = new FormData()

    formData.append("file_name", targetFilename);

    axios({
      method: "post",
      url: API_ENDPOINT_DOWNLOAD, 
      data: formData, 
      responseType: 'blob',
      headers: {"Content-Type": "multipart/form-data" }
    })
      .then((response) => {
        const href = URL.createObjectURL(response.data);
    
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', targetFilename); 
        document.body.appendChild(link);
        link.click();
    
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
        setIsRequestInProcess(false);
      })
      .catch((error) => {
        console.log(error)
        setIsRequestInProcess(false)
      });
  };

  return (
    <Form>
      <Form.Group className="mb-3" controlId="formResultFilename">
        <Form.Label>Имя файла-результата</Form.Label>
        <Form.Control type="text" placeholder="Введите имя файла-результата" value={targetFilename} onChange={handleTargetFilenameChange}/>
      </Form.Group>
      <Button variant="primary" type="submit" disabled={isRequestInProcess} onClick={handleDownloadAction}>
        Скачать
      </Button>
    </Form>
  );
}

export default FormDownloadResult;