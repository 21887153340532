import * as React from 'react';
import axios from 'axios';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import { LabelYoStory } from '../../elements/LabelYoStory';
import { ButtonGradient } from '../../elements/Buttons';
import { Link } from 'react-router-dom';

// const API_ENDPOINT_DETECT_IMAGE = `${process.env.REACT_APP_API_HOST_IP}/api/v1/detect/image`

const Home = () => {
 
  return (
    <>
    <Stack gap={2} className="col-md-5 mx-auto">
      <center>
        <LabelYoStory />
      </center>
      <ButtonGradient as={Link} to="/account" variant="primary">
        Мой аккаунт
      </ButtonGradient>
      <ButtonGradient as={Link} to="/subscription" variant="primary">
        Оформить подписку
      </ButtonGradient>
      <ButtonGradient as={Link} to="/referral" variant="primary" >
        Реферальная программа
      </ButtonGradient>
      <ButtonGradient as={Link} to="https://t.me/yostoryru" variant="primary">
        Канал YoStory
      </ButtonGradient>
      <ButtonGradient as={Link} to="/information" variant="primary">
        Правовая информация
      </ButtonGradient>
    </Stack>
    </>
  );
}

export default Home;